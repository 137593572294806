import * as React from 'react'
import * as Styles from './header.module.sass'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from './seo'
import MobileMenu from './mobilemenu'
const Header = () => {
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `
  )

  return (
    <>
      <Seo
        title={`${site.siteMetadata.title}`}
        titleTemplate={`%s`}
        image={{ src: `/images/social_preview_home.png`, width: 1200, height: 628 }}
        description={`${site.siteMetadata.description}`}
      />
      <div className={`${Styles.main}`}>
        <div className={`columns is-vcentered is-mobile is-gapless`}>
          <MobileMenu />
          <div className={`column is-2 is-parent`}>
            <div className={`is-flex is-justify-content-end is-flex-direction-row ${Styles.phone}`}>
              <div>
                <a href={`tel:+79959108588`}>
                  <picture>
                    <source media={`(max-width: 1023px)`} srcSet={`/images/phone-m_1x.png 1x, /images/phone-m_2x.png 2x`} type={`image/png`} />
                    <source media={`(min-width: 1024px)`} srcSet={`/images/phone_1x.png 1x, /images/phone_2x.png 2x`} type={`image/png`} />
                    <img alt={`Позвонить в Mustbefamily`} src={`/images/phone_1x.png`} type={`image/png`} />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}
export default Header
