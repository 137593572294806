import * as React from 'react'
import * as Styles from "./mobilemenu.module.sass"
import { Link } from 'gatsby'
import { HamburgerButton } from 'react-hamburger-button'
import { useInView } from 'react-intersection-observer'
import ContextConsumer from '../layouts/context'
const MenuLink = ({ onClick, link, text }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <div className={(data.menuOpen && inView) ? `${Styles.link} ${Styles.inview}` : `${Styles.link}`}>
          <Link
            ref={ref}
            activeClassName={Styles.active}
            partiallyActive={true}
            onClick={onClick}
            to={`${link}`}>
            {text}
          </Link>
        </div>
      )}
    </ContextConsumer>)
}
const MobileMenu = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <>
          <div className={`${Styles.cookielink}`}>
            <a href={`/cookie`} target={`_blank`}>Мы используем куки</a>
          </div>
          <div className={`column is-2`}>
            <div className={`is-flex is-justify-content-start ${Styles.overlay} ${data.menuOpen ? Styles.opened : ``}`}>
              <div className={`is-flex is-flex-direction-column is-justify-content-center ${Styles.halfheight}`}>
                <MenuLink onClick={() => set({ menuOpen: false })} link={`/video`} text={`Видео`} />
                {/*                 <MenuLink onClick={() => set({ menuOpen: false })} link={`/websites`} text={`Cайты`} />
                <MenuLink onClick={() => set({ menuOpen: false })} link={`/team`} text={`Команда`} /> */}
                <MenuLink onClick={() => set({ menuOpen: false })} link={`/contacts`} text={`Контакты`} />
                <div ref={ref} className={(data.menuOpen && inView) ? `${Styles.link} ${Styles.inview}` : `${Styles.link}`}>
                  <a href={`https://3djew.mustbefamily.com/`} target={`_blank`}>Ювелирным брендам</a>
                </div>
                <div ref={ref} className={(data.menuOpen && inView) ? `${Styles.link} ${Styles.inview}` : `${Styles.link}`}>
                  <a href={`https://www.youtube.com/c/mustbefamily`} target={`_blank`} className={`${Styles.iconlink}`}>
                    <picture>
                      <source media={`all`} srcSet={`/images/youtube_1x.png 1x, /images/youtube_2x.png 2x`} type={`image/png`} />
                      <img alt={`Mustbefamily youtube`} src={`/images/youtube_1x.png`} type={`image/png`} />
                    </picture>
                  </a>
                  <a href={`https://www.instagram.com/mustbefamily/`} target={`_blank`} className={`${Styles.iconlink}`}>
                    <picture>
                      <source media={`all`} srcSet={`/images/instagram_1x.png 1x, /images/instagram_2x.png 2x`} type={`image/png`} />
                      <img alt={`Mustbefamily insta`} src={`/images/instagram_1x.png`} type={`image/png`} />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
            <nav className={``}>
              <div className={`${Styles.hamburger}`}>
                <HamburgerButton
                  open={data.menuOpen}
                  onClick={() => set({ menuOpen: !data.menuOpen })}
                  width={30}
                  height={20}
                  strokeWidth={2}
                  color='white'
                  animationDuration={0.1}
                /></div>
            </nav>
          </div>
          <div className={`column`}>
            <div className={`is-flex ${Styles.center}`}>
              <Link to={`/`} onClick={() => set({ menuOpen: false })}>
                <picture>
                  <source media={`(max-width: 1023px)`} srcSet={`/images/logo-m_1x.png 1x, /images/logo-m_2x.png 2x`} type={`image/png`} />
                  <source media={`(min-width: 1024px)`} srcSet={`/images/logo_1x.png 1x, /images/logo_2x.png 2x`} type={`image/png`} />
                  <img alt={`Mustbefamily logo`} src={`/images/logo_1x.png`} type={`image/png`} />
                </picture>
              </Link>
            </div>
          </div>

        </>
      )}
    </ContextConsumer>
  )
}
export default MobileMenu
